import {
	StartPage,
	FavoriteDessertPage,
	FlavourGroupsPage,
	CoffeeColorPage,
  LoadingResultsPage,
  ResultsPage
} from "../pages";
import {ResultsPage as ResultsPageOld} from '../pages/Results_old'

export const ROUTES = {
	start: {
		next: "favorite_dessert",
		component: StartPage
	},
	favorite_dessert: {
		prev: "start",
		next: "flavor_groups",
		component: FavoriteDessertPage,
		question_count: 1
	},
	flavor_groups: {
		prev: "favorite_dessert",
		next: "coffee_color",
		question_count: 2,
		component: FlavourGroupsPage
	},
	coffee_color: {
		prev: "flavor_groups",
		next: "loading_results",
		question_count: 3,
		component: CoffeeColorPage
	},
	loading_results: {
		prev: "coffee_color",
		next: "results",
		question_count: 4,
		component: LoadingResultsPage
	},
	results: {
    prev: "coffee_color",
		component: ResultsPage
		// component: ResultsPageOld,
	}
};
