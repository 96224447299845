// Hooks
import { useState, useEffect } from "react";
// Config
import { CONTENT, QUIZ } from "../config";

import { Flex, Heading, Text, Progress } from "theme-ui";

function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export const LoadingResultsPage = ({ handleAnswerSelection, completeQuiz }) => {
	// Content
	const { title, subtitle } = CONTENT.loadingResultsPage;
	const { options } = QUIZ.flavourGroups;
	// Progress
	const [prog, setProg] = useState(0);

	const loop = count => {
		// Create random progress increment and random delay increment
		var randProg = randomIntFromInterval(5, 45);
		var randTime = randomIntFromInterval(3, 20) * 100;
		
		// update progress
		setProg(count + randProg);
		count = count + randProg;

		// Loop or pass to next page
		if (count < 100) {
			setTimeout(() => {
				loop(count);
			}, randTime);
		} else {
      setTimeout(() => {
				handleAnswerSelection({})
			}, 500);
    }
	};

	useEffect(() => {
		completeQuiz()
		// Start Loading
		const start = 0;
		loop(start);
	}, []);
	return (
		<Flex
			sx={{
				height: "100%",
				width: "100%",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center"
			}}
		>
			<Heading as="h1" sx={{ mt: 0, mb: 4 }} variant="heading">
				{title}
			</Heading>
			<Text
				as="p"
				sx={{
					fontSize: [null, 2, 3],
					color: "grey",
					maxWidth: [null, null, "50%", "40%"],
					// display: ["none", "none", "initial"]
				}}
			>
				{subtitle}
			</Text>
			<Flex sx={{width: ['90%','90%',"80%"], mt: 5}}>
				<Progress
					sx={{
						"::-webkit-progress-value": {
							borderRadius: "100px",
							padding: "2px",
							border: "3px solid black",
							marginLeft: prog < 10 ? "-8px" : "-2px",
							transform: "scale(1.01)",
							marginTop: "-4px",
							height: "24px",
							transition: ".3s ease"
						}
					}}
					variant="variants.progress"
					max={1}
					value={prog / 100}
				>
					40%
				</Progress>
			</Flex>
		</Flex>
	);
};
