import { keyframes } from "@emotion/core";

export const wobble = keyframes({
	from: {
	    transform: 'translate3d(0,0,0)'
    },
    '25%': {
        transform: 'translate3d(2px,0,0)'
    },
    // '25%': {
    //     // transform: 'translate3d(2px,0,0)'
    // },
	to: {
	    transform: 'translate3d(0,0,0)'
	}
	// from: {
	// 	transform: "rotate(0deg)"
	// },
	// to: {
	// 	transform: "rotate(360deg)"
	// }
});
