// Hooks
import { useReducer, useEffect } from "react";
// CSS Reset
import "./utils/styles/normalize.min.css";
// Config
import { ROUTES, QUIZ } from "./config";
// Tracking
import ReactPixel from 'react-facebook-pixel';
// Layouts
import { ThemeProvider, Box, Flex } from "theme-ui";
import { GlobalStyle, theme } from "./theme";
import { CoreLayout, withQuestionLayout } from "./components/layouts";


import { reducer, initialState } from "./state/reducer";

export default function App() {
	// State
	const [{ routerCurrentPage, activeResult, quizResponses, quizResults, }, dispatch] = useReducer(reducer, initialState);
	// Routing Selectors
  const currentPage = ROUTES[routerCurrentPage];
	const nextPage = currentPage.next || null;
	const prevPage = currentPage.prev || null;
	const questionCount = currentPage.question_count;
	const numberOfQuestions = QUIZ.config.number_of_questions;
	const questionTracker = { questionCount, numberOfQuestions };

	const CurrentBase = currentPage.component;
	const CurrentPageComponent = currentPage.question_count ? (
		withQuestionLayout({
			component: currentPage.component,
			dispatch,
			questionTracker,
			nextPage,
			prevPage,
			quizResponses,
		})
	) : (
		<CurrentBase {...{ dispatch, nextPage, prevPage, questionTracker, activeResult, quizResults, }} />
	);

	useEffect(() => {
		const options = {
			autoConfig: true, // set pixel's autoConfig
			debug: false, // enable logs
		};
		ReactPixel.init('133949907240957', options);
		console.log('made with love by IAmNatch ♥')
	}, []);
	// Always start new pages at the top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [CurrentPageComponent]);

	useEffect(() => {
		if (routerCurrentPage === 'results') {
			window.history.replaceState({}, 'Quiz',`results--${quizResults.join('--')}`)
		} else {
			window.history.replaceState({}, 'Quiz', routerCurrentPage);
		}
		ReactPixel.pageView();
	}, [routerCurrentPage])

	return (
		<Flex sx={{ height: "100%", width: "100%" }}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<CoreLayout>{CurrentPageComponent}</CoreLayout>
			</ThemeProvider>
		</Flex>
	);
}
