export const CONTENT = {
	promoBar: `**FREE SHIPPING** ON ORDERS $55+ // WE'RE STILL SHIPPING, BUT PLEASE ALLOW FOR SLIGHT DELAY IN PROCESSING AND DELIVERY TIMES DUE TO COVID-19 IMPACT ON CARRIERS & WAREHOUSE WORKERS`,
	socialLinks: [
		"https://facebook.com/prymalcoffeecreamer",
		"https://www.pinterest.com/prymalcoffeecreamer/",
		"https://instagram.com/prymalcoffeecreamer",
		"mailto:support@prymal.com"
	],
	startPage: {
		title: "Find your perfect creamer.",
		subtitle:
			"Take this quiz to find out which Prymal Coffee Creamer is right for you.",
		buttonText: "Get Started"
	},
	favoriteDessertPage: {
		title: "What’s your favorite dessert?",
		subtitle:
			"What yummy treats do you feel sorry, not sorry, for loving? This will help us understand what you look for in decadence."
	},
	flavourGroupsPage: {
		title: "Which group of creamer flavors sounds most appealing?",
	},
	coffeeColorPage: {
		title: "What color do you like your coffee?",
		subtitle: "This will help us recomend the best way to prepare your coffee for optimal enjoyment.",
	},
	loadingResultsPage: {
		title: "We're brewing your flavor...",
		subtitle: "Hold tight, we’re matching you with flavours that will rock your tastebuds.",
  },
};
