const flavours = {
	vanilla: {
		description: "",
		image: "VANILLA.png",
		id: "vanilla"
	},
	cinnamon: {
		description: "",
		image: "cinnamon.png",
		id: "cinnamon"
	},
	caramel: {
		description: "",
		image: "CARAMEL.png",
		id: "caramel"
	},
	pastry: {
		description: "",
		image: "PASTRY.png",
		id: "pastry"
	},
	chocolate: {
		description: "",
		image: "CHOCOLATE.png",
		id: "chocolate"
	},
	nuts: {
		description: "",
		image: "NUTTY.png",
		id: "chocolate"
	},
	creamer: {
		description: "",
		image: "",
		id: "creamer"
	},
};

export const QUIZ = {
	config: {
		number_of_questions: 4
	},
	favoriteDessert: {
		options: [
			{ title: "Cookies & Cakes", flavours: ['vanilla', 'butter'], image: "cupcake", wide: true },
			{ title: "Pies & Pastries", flavours: ['pastry', 'nuts'], image: "croissant" },
			{ title: "Chocolate Anything", flavours: ['chocolate', 'caramel'], image: "brownie" },
			{ title: "Ice Cream", flavours: ['vanilla', 'fruit'], image: "icecream" }
		],
	},
	flavourGroups: {
		options: [
			{ id: 1, values: ["Creme Brulee", "Pumpkin Spice", "Cinnabon"], flavours: ['cinnamon', 'pastry'] },
			{
				id: 2,
				values: [
					"Double Chocolate Chunk",
					"Red Velvet Cake",
					"Oreo/Cookies & Cream"
				],
				flavours: ['chocolate', 'nuts'],
			},
			{ id: 3, values: ["Salted Caramel", "Snickers", "Reeses"], flavours: ['caramel', 'butter'] },
			{ id: 4, values: ["Irish Cream", "Vanilla", "Italian Sweet Cream"], flavours: ['vanilla', 'fruit'] }
		],
	},
	flavours,
	products: {
		// insert_name: {
		// 	id: "",
		// 	title: "",
		// 	flavour: "",
		// 	theme: {
		// 		backgroundColor: ""
		// 	},
		// 	content: {
		// 		tastesLike: "",
		// 		customersSay: "",
		// 		useItIn: "",
		// 		tagline: ""
		// 	},
		// },
		variety_pack: {
			id: "variety_pack",
			title: "Variety Pack",
			flavour: null,
			link: '',
			image: 'variety.png',
			theme: {
				backgroundColor: "#B38557"
			},
			content: {
				tastesLike: "Lorem ipsum dolor amet",
				customersSay: "LOREM IMPUM DOLOR. Prymal, yet again, helps me satisfy my cravings for a sweet treat! The Speculoos Cookie Dough creamer tastes amazing in coffee, but also rich and decadent as a steamer made with just almond milk. So good!”",
				useItIn: "Lorem ipsum, coffee, oatmeal, hot milk steamer",
				tagline: "Can’t decide? No problem! This sampler is perfect for gifting or to discover which you like best before committing to a larger size. Each bag…"
			},
		},
		peppermint_mocha: {
			id: "peppermint_mocha",
			title: "Peppermint Mocha",
			flavour: "chocolate",
			link: '',
			image: 'peppermint.png',
			theme: {
				backgroundColor: "#C53F42"
			},
			content: {
				tastesLike: "Lorem ipsum dolor amet",
				customersSay: "“LOREM IMPUM DOLOR. Prymal, yet again, helps me satisfy my cravings for a sweet treat! The Speculoos Cookie Dough creamer tastes amazing in coffee, but also rich and decadent as a steamer made with just almond milk. So good!”",
				useItIn: "Lorem ipsum, coffee, oatmeal, hot milk steamer",
				tagline: "“Winter has come. Stay in and sip on this decadent cocoa with a subtle kiss of sweet peppermint.”"
			},
		},
		original: {
			id: "original",
			title: "The Original",
			flavour: "creamer",
			link: '',
			image: 'original.png',
			theme: {
				invert: true,
				backgroundColor: "#7BAEC3"
			},
			content: {
				tastesLike: "Heavy cream or half & half",
				customersSay: "“Finally a healthy creamer that doesn’t taste like sadness and water. It doesn’t have any weird aftertaste, and best of all it’s ACTUALLY CREAMY, unlike all the other healthy creamers I’ve tried.”",
				useItIn: "Coffee, oil/butter replacement in baked goods or pancakes",
				tagline: "“Milky-rich cream. This no fluff, no fuss addition lets your favourite brew remain the star.”"
			},
		},
		roasted_hazelnut: {
			id: "roasted_hazelnut",
			title: "Roasted Hazelnut",
			flavour: "nuts",
			link: '',
			image: 'hazelnut.png',
			theme: {
				backgroundColor: "#997464"
			},
			content: {
				tastesLike: "If a snickerdoodle cookie and a gingerbread man had a baby",
				customersSay: "“CREAMY COOKIE COFFEE. Prymal, yet again, helps me satisfy my cravings for a sweet treat! The Speculoos Cookie Dough creamer tastes amazing in coffee, but also rich and decadent as a steamer made with just almond milk. So good!”",
				useItIn: "Coffee, oatmeal, hot milk steamer, candied nuts, cookie batter",
				tagline: "“A hearth & home classic. Full-bodied noted of slow roasted hazelnuts finished with sweet cream.”"
			},
		},
		cacao_mocha: {
			id: "cacao_mocha",
			title: "Cacao Mocha",
			flavour: "chocolate",
			link: '',
			image: 'cacao.png',
			theme: {
				backgroundColor: "#7D6762"
			},
			content: {
				tastesLike: "Exactly like a decadent chocolate",
				customersSay: " “I am obsessed with the cacao mocha. I’ve tried the other creamers too but there is nothing like this one. While I make my espresso, I steam heavy cream but the moment this creamer hits the cream, magic happens. The smell is divine, and the flavor not far behind. It truly brings back childhood memories of hot chocolate, chocolate malted milk, you name it”",
				useItIn: "Coffee, hot milk (use like hot cocoa mix), in protein shakes with peanut butter",
				tagline: "“Rich and savory. Tastes like your childhood favorite hot chocolate, swirled with steamed milk.”"
			},
		},
		birthday_cake: {
			id: "birthday_cake",
			title: "Birthday Cake",
			flavour: "pastry",
			link: '',
			image: 'speculoosCookies.png',
			theme: {
				backgroundColor: "#F0DCE3",
				invert: true,
			},
			content: {
				tastesLike: "Yellow cake and icing",
				customersSay: "“TASTES LIKE CAKE WITH SPRINKLES This flavor is so good, after my first taste, the smile was on my face. With it having the MCT its a perfect keto option. I like to mix the powder with heavy cream and coffee in a shaker bottle for a perfect taste.”",
				useItIn: "Coffee, plain yogurt, hot milk steamer, cheesecake filling",
				tagline: "“Cake for breakfast, everyday. enjoy notes of bakery-fresh yellow cake with whipped buttercream & sprinkles"
			},
		},
		pumpkin_spice: {
			id: "pumpkin_spice",
			title: "Pumpkin Spice",
			flavour: "cinnamon",
			link: '',
			image: 'pumpkinSpice.png',
			theme: {
				backgroundColor: "#DC835F"
			},
			content: {
				tastesLike: "Lorem ipsum dolor amet",
				customersSay: "Prymal, yet again, helps me satisfy my cravings for a sweet treat! The Speculoos Cookie Dough creamer tastes amazing in coffee, but also rich and decadent as a steamer made with just almond milk. So good!”",
				useItIn: "Lorem ipsum, coffee, oatmeal, hot milk steamer",
				tagline: "“Fall in a cup. Flavor medley of cinnamon spices, pumpkin pie filling & golden-brown pie crust.”"
			},
		},
		vanilla_bean: {
			id: "vanilla_bean",
			title: "Vanilla Bean",
			flavour: "vanilla",
			link: '',
			image: 'vanilla.png',
			theme: {
				backgroundColor: "#EFE4D5",
				invert: true
			},
			content: {
				tastesLike: "Vanilla soft serve or vanilla custard",
				customersSay: "“Heat coconut milk and add the vanilla creamer and froth it with the aerolatte. Pour coffee on top, add nutmeg, and it’s a latte that tastes just as good as any Starbucks latte!”",
				useItIn: "Coffee, pancake batter, hot milk steamer, candied nuts, cookie batter",
				tagline: "“Simple & pure. Reminiscent of traditional vanilla soft serve, straight from your local diner.”"
			}
		},
		cinnamon_dolce: {
			id: "cinnamon_dolce",
			title: "Cinnamon Dolce",
			flavour: "cinnamon",
			link: '',
			image: 'cinnaminDolce.png',
			theme: {
				backgroundColor: "#AA6F55"
			},
			content: {
				tastesLike: "Cinnamon rolls & brown sugar crumble",
				customersSay:
					"“CAN’T WAIT TO START MY DAY. I love this new addition to my morning coffee. Sweet but not overwhelming. Makes my heart happy!”",
				useItIn:
					"Coffee, french toast batter, sprinkled on diced apples, candied nuts, oatmeal",
				tagline:
					"“A golden ratio of brown sugar, cinnamon swirls & milk. This is coffeehouse comfort in a cup”"
			}
		},
		salted_caramel: {
			id: "salted_caramel",
			title: "Salted Caramel",
			flavour: "caramel",
			link: '',
			image: 'saltedCaramel.png',
			theme: {
				backgroundColor: "#C7A475"
			},
			content: {
				tastesLike: "Tastes like a gooey caramel chew—sweet & salty",
				customersSay:
					"“HAPPINESS IN A CUP! I love coffee and have learned to drink it black due to having Insulin Resistance. I really miss having cream and flavoring. So when I saw this pop up on my Instagram feed, I did some research and decided to try it. I ordered a bag of Salted Caramel and Vanilla Bean. I LOVE both of them! …”",
				useItIn: "Coffee, brownie batter, hot milk steamer, plain yogurt",
				tagline:
					"“Sweet and salty decadence. Base notes of fluffy whip with ribbons of caramel drizzle.”"
			}
		}
	}
};
