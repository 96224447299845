// Config
import { CONTENT } from "../config";
// Actions
import { setRouterCurrentPage } from "../state/actions";
// Components
import { Flex, Box, Text, Heading, Button } from "theme-ui";

export const StartPage = ({ dispatch, nextPage }) => {
	const { title, subtitle, buttonText } = CONTENT.startPage;
	return (
		<Box sx={{ height: '100%' }}>
			{/* Fancy Background */}
			<Box
				sx={{
					backgroundImage: "url(screen.png)",
					height: "100%",
					width: "100%",
					backgroundSize: "cover",
					backgroundPosition: "center",
					position: "absolute",
					top: 0,
					zIndex: -2
				}}
			>
				<Box
					sx={{ backgroundColor: "rgba(255,255,255,.95)", height: "100%" }}
				></Box>
			</Box>

			{/* Content */}
			<Flex
				sx={{
					height: "100%",
					width: "100%",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
					// paddingTop: "25%",
				}}
			>
				<Heading as="h1">{title}</Heading>
				<Text
					sx={{ fontSize: [3], textAlign: "center", maxWidth: "40%" }}
					mb={4}
					color="grey"
				>
					{subtitle}
				</Text>
				<Button
					onClick={() => dispatch(setRouterCurrentPage({ page: nextPage }))}
					variant="primary"
          
          sx={{
            mr: 2,
            minWidth: 245,
          }}
				>
					{buttonText}
				</Button>
			</Flex>
		</Box>
	);
};
