import {Flex, Text} from 'theme-ui';

export const BreakPointUtility = () => (
	<Flex
		sx={{
			position: "absolute",
			top: "0",
			right: 0,
			width: "200px",
			height: "100px",
			bg: "purple",
			color: "white",
			justifyContent: "center",
			alignItems: "center",
			fontSize: 8
		}}
	>
		<Text sx={{ display: ["block", "none", "none", "none", "none"] }}>1</Text>
		<Text sx={{ display: ["none", "block", "none", "none", "none"] }}>2</Text>
		<Text sx={{ display: ["none", "none", "block", "none", "none"] }}>3</Text>
		<Text sx={{ display: ["none", "none", "none", "block", "none"] }}>4</Text>
		<Text sx={{ display: ["none", "none", "none", "none", "block", "none"] }}>
			5
		</Text>
		<Text sx={{ display: ["none", "none", "none", "none", "none", "block"] }}>
			6
		</Text>
	</Flex>
);