// Hooks
import { useState } from "react";
// Config
import { CONTENT, QUIZ } from "../config";
// Components
import { Flex, Box, Text, Heading, Button, css } from "theme-ui";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Track, Handle, Tick } from "../components/atoms/slider/Slider";
// Utils
import { mq } from "../theme/theme";
import { ClassNames } from "@emotion/core";

export const CoffeeColorPage = ({ handleAnswerSelection }) => {
	// Content
	const { title, subtitle } = CONTENT.coffeeColorPage;
	const { options } = QUIZ.flavourGroups;

	/***** Slider *****/
	// State
	const [values, setValues] = useState([1]);
	const [isSliderActive, setIsSliderActive] = useState(false);
	// Config
	const domain = [1, 4];
	// Handlers
	const onChange = values => {
		setValues(values);
	};
	//Style
	const sliderStyle = css({
		[mq[0]]: {
			position: "relative",
			width: "100%",
			mt: 5,
			mb: 175
		},
		[mq[4]]: {
			position: "absolute",
			width: "75%",
			transform: "translate(-50%, -50%)",
			left: "50%",
			top: "50%"
		}
	});
	const railStyle = {
		position: "absolute",
		width: "100%",
		height: 24,
		borderRadius: 12,
		border: "2px solid black",
		background:
			"linear-gradient(90deg, rgba(90,48,5,1) 0%, rgba(190,139,85,1) 50%, rgba(255,226,193,1) 100%)",
		cursor: "pointer"
	};
	return (
		<Flex
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				height: "100%",
				minHeight: 0
			}}
		>
			<Heading as="h1" sx={{ mt: [1, 1, 80] }} variant="heading">
				{title}
			</Heading>
			<Text
				as="p"
				sx={{
					fontSize: [null, 2, 3],
					color: "grey",
					maxWidth: [null, null, "50%", "40%"],
					display: ["none", "none", "initial"]
				}}
			>
				{subtitle}
			</Text>

			{/* Slider */}
			<Flex
				sx={{ width: ["90%", "80%", "70%"], flex: "1", alignItems: "center" }}
			>
				<ClassNames>
					{({ css, cx }) => (
						<Slider
							onSlideStart={() => setIsSliderActive(true)}
							onSlideEnd={val => {
								setIsSliderActive(false);
								setValues([Math.round(val)]);
							}}
							mode={1}
							step={0.1}
							domain={domain}
							className={`hello ${css(sliderStyle())}`}
							onChange={onChange}
							values={values}
						>
							<Rail>
								{({ getRailProps }) => (
									<div style={railStyle} {...getRailProps()} />
								)}
							</Rail>
							<Handles>
								{({ handles, getHandleProps }) => (
									<div className="slider-handles">
										{handles.map(handle => (
											<Handle
												isSliderActive={isSliderActive}
												key={handle.id}
												handle={handle}
												domain={domain}
												getHandleProps={getHandleProps}
											/>
										))}
									</div>
								)}
							</Handles>
							<Tracks right={false}>
								{({ tracks, getTrackProps }) => (
									<div className="slider-tracks">
										{tracks.map(({ id, source, target }) => (
											<Track
												key={id}
												source={source}
												target={target}
												getTrackProps={getTrackProps}
											/>
										))}
									</div>
								)}
							</Tracks>
							<Ticks count={4}>
								{({ ticks }) => {
									return (
										<Box sx={{ marginTop: 3 }} className="slider-ticks">
											{ticks.map(tick => {
												let val = "";
												if (tick.value === 1) {
													val = "Dark";
												} else if (tick.value === 2) {
													val = "A Dash of Color";
												} else if (tick.value === 3) {
													val = "On the Lighter Side";
												} else if (tick.value === 4) {
													val = "Very Creamy";
												}
												return (
													<Tick
														key={tick.id}
														tick={{ ...tick, value: val }}
														count={ticks.length}
													/>
												);
											})}
										</Box>
									);
								}}
							</Ticks>
						</Slider>
					)}
				</ClassNames>
			</Flex>

      {/* Submit */}
			<Flex
				sx={{
					alignSelf: "flex-end",
					justifyContent: "center",
					width: "100%",
					mb: [4, 5, 6]
				}}
			>
				<Button sx={{minWidth: 245}} onClick={() => handleAnswerSelection({})}>Continue</Button>
			</Flex>
		</Flex>
	);
};
