// Hooks
import { useState } from "react";
// Config
import { CONTENT, QUIZ } from "../config";
// Actions
import { setCurrentPage } from "../state/actions";
// Components
import { Flex, Box, Text, Heading, Image, Card } from "theme-ui";
import { QuestionLayout } from "../components/layouts/withQuestionLayout";

export const FlavourGroupsPage = ({ handleAnswerSelection }) => {
	// Content
	const { title, subtitle } = CONTENT.flavourGroupsPage;
	const { options } = QUIZ.flavourGroups;

	// Handle hover effect state
	const defaultHoverState = options.reduce((acc, option, idx) => {
		return { ...acc, [idx]: false };
	}, {});

	const [hoverStates, setHoverState] = useState(defaultHoverState);

	const updateHoverState = ({ idx, val }) =>
		/**
		 * This function takes an specific card index and
		 * updates its hover state.  It uses defaultHoverState
		 * to ensure that even if mouseLeave event doesnt fire
		 * that only one is hovered at a time.
		 */
		setHoverState({
			...defaultHoverState,
			[idx]: val
		});

	return (
		<Box
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center"
			}}
		>
			<Heading
				as="h1"
				sx={{ mt: [0, 0, 80], mb: [null, null, null, null, 5, 6] }}
				variant="heading"
			>
				{title}
			</Heading>
			{/* <Text
				as="p"
				sx={{
					fontSize: [null, 2, 3],
					color: "grey",
					maxWidth: [null, null, "50%", "40%"],
					display: ["none", "none", "initial"]
				}}
			>
				{subtitle}
			</Text> */}
			{/* Cards */}
			<Flex
				sx={{
					height: "auto",
					width: "100%",
					maxWidth: 1000,
					mt: [5],
					mx: "auto",
					flexWrap: "wrap",
					alignItems: "space-between",
					justifyContent: "center",
					flex: 1
				}}
			>
				{options?.map((option, idx) => (
					<Card
						key={option.id}
						onMouseEnter={() => updateHoverState({ idx, val: true })}
						onMouseLeave={() => updateHoverState({ idx, val: false })}
						onClick={() => handleAnswerSelection({ response: option.flavours })}
						sx={{
							minWidth: ["35%", "35%", 0, 0],
							height: "100%",
							maxWidth: [null, null, "180px", "180px", "unset"],
							flex: "1",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-center",
							justifyContent: "space-between",
							border: "none",
							mx: [1, 2, 3],
							p: 0,
							mb: [idx + 1 <= options.length - 2 ? 5 : 0, 5, 0, 0], // mobile: exclude last 2
							":hover": {
								border: "none",
								transform: ["", "", "scale(1.05)"]
							}
						}}
					>
						{option.values.map((flavour, ydx) => (
							<Flex
								key={flavour}
								sx={{
									height: 100,
									justifyContent: "center",
									alignItems: "center",
									my: ydx === 1 && "-2px",
									border: hoverStates[idx]
										? "2px solid black"
										: "2px solid grey",
									borderTopLeftRadius: ydx === 0 && "rounded",
									borderTopRightRadius: ydx === 0 && "rounded",
									borderBottomLeftRadius:
										ydx + 1 === option.values.length && "rounded",
									borderBottomRightRadius:
										ydx + 1 === option.values.length && "rounded"
								}}
							>
								<Text sx={{}}>{flavour}</Text>
							</Flex>
						))}
					</Card>
				))}
			</Flex>
		</Box>
	);
};
