import { Image, Flex, Text } from "theme-ui";

export const Icon = ({ icon, width, minWidth, height, description, ...rest }) => (
	<Flex
		sx={{
			flexDirection: "row",
			alignItems: "center",
			cursor: "pointer"
		}}
		{...rest}
	>
		<Image
			variant={"icon"}
			sx={{ width: width ||[15, 20, 25], height: height || [15, 20, 25], minWidth: minWidth || ''}}
			src={`/icons/${icon}.svg`}
		/>
		{description && (
			<Text
				sx={{
					color: "black",
					fontSize: [1, 2],
					fontWeight: "bold",
					ml: 2
				}}
			>
				{description}
			</Text>
		)}
	</Flex>
);
