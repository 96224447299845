export const theme = {
	colors: {
		// Colors
		black: "#362F2B",
		gray: "#707070",
		// Words
		text: "#362F2B",
		background: "#fff",
		primary: "#BE8B55",
		primaryLight: 'rgba(190, 139, 85, 0.15)',
		secondary: "#30c",
		muted: "#F8F5F5",
	},
	breakpoints: ["576px", "768px", "992px", "1200px", "1500px"],
	fonts: {
		body: "Work Sans, sans-serif;",
		heading: "font-family: 'Raleway', sans-serif",
		monospace: "Menlo, monospace"
	},
	fontSizes: [12, 14, 16, 20, 24, 28, 32, 48, 64, 96],
	fontWeights: {
		body: 400,
		heading: 700,
		bold: 700
	},
	lineHeights: {
		body: 1.2,
		heading: 1.25
	},
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	sizes: {
		avatar: 48
	},
	radii: {
		default: 0,
		rounded: 8,
		circle: 99999
	},
	borders: {
		default: "3px solid black",
		slim: "2px solid black",
		transparent: "3px solid white",
		disabled: "3px solid grey"
	},
	shadows: {
		card: "0 0 4px rgba(0, 0, 0, .125)"
	},
	// rebass variants
	text: {
		heading: {
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: [4, 5, 5, 6, 7]
		},
		title: {
			fontFamily: "heading",
			fontWeight: "heading",
			lineHeight: "heading",
			fontSize: [6, 7, 8]
		},
		caps: {
			textTransform: "uppercase",
			letterSpacing: "0.1em"
		}
	},
	images: {
		icon: {
			width: "25px",
			height: "25px"
		}
	},
	cards: {
		base: {
			padding: 2,
			borderRadius: "rounded",
			background: "#fff",
			border: "transparent"
		},
		primary: {
			padding: 2,
			borderRadius: "rounded",
			background: "#fff",
			border: "transparent",
			transition: ".2s ease",
			":hover": {
				backgroundColor: "primary",
				border: "default",
				color: "white"
			}
		},
		short: {
			borderRadius: 60,
			border: 'transparent',
		}
	},
	variants: {
		progress: {
			border: "slim",
			color: "primary",
			bg: "transparent",
			height: 20
			// "::-webkit-progress-value": {
			//   borderRadius: "rounded",
			//   padding: "2px",
			//   border: "2px solid black",
			//   height: '110%',
			// }
		},
		avatar: {
			width: "avatar",
			height: "avatar",
			borderRadius: "circle"
		},
		card: {
			p: 2,
			bg: "background",
			boxShadow: "card"
		},
		link: {
			color: "primary"
		},
		nav: {
			fontSize: 1,
			fontWeight: "bold",
			display: "inline-block",
			p: 2,
			color: "inherit",
			textDecoration: "none",
			":hover,:focus,.active": {
				color: "primary"
			}
		}
	},
	buttons: {
		base: {
			fontSize: 3,
			fontFamily: "body",
			fontWeight: "bold",
			cursor: 'pointer'
			// minWidth: 245
		},
		primary: {
			variant: "buttons.base",
			color: "text",
			bg: "transparent",
			borderRadius: "default",
			position: "relative",
			zIndex: "1",
			// Add border separately to allow Z-Index 1 on the bottom.
			// Z-Index 1 is required to create a new z index context
			// https://stackoverflow.com/questions/3032856/is-it-possible-to-set-the-stacking-order-of-pseudo-elements-below-their-parent-e
			"::before": {
				content: '""',
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: "100%",
				height: "100%",
				border: "3px solid black",
				boxSizing: "border-box"
			},
			"::after": {
				zIndex: -1,
				content: '""',
				display: "block",
				position: "absolute",
				bottom: "-10px",
				left: "-5%",
				width: "100%",
				height: "100%",
				backgroundColor: "primary",
				transition: ".3s cubic-bezier(0.250, 0.460, 0.450, 0.940)"
			},
			":hover:not([disabled])": {
				"::after": {
					// width: "100%",
					// height: "100%",
					left: 0,
					bottom: 0
				}
			},
			":disabled": {
				"::after": {
					backgroundColor: "grey"
				}
			}
		},
		outline: {
			variant: "buttons.base",
			borderRadius: "default",
			border: "default",
			color: "black",
			bg: "transparent",
			":disabled": {
				border: "disabled",
				color: "grey"
			}
		}
	},
	styles: {
		root: {
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body"
		}
	}
};

/**
 * Media Queries for use in @emotion ClassName wrappers.
 */
export const mq = theme.breakpoints.map(
	(bp, idx) => `@media (min-width: ${idx === 0 ? "0px" : bp})`
);
