import { Global } from "@emotion/core";

export const GlobalStyle = props => (
	<Global
		styles={theme => ({
			"*": {
				// border: "1px solid red",
			}
		})}
	/>
);
