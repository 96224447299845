// Hooks
import { useState } from "react";
// Config
import { CONTENT, QUIZ } from "../config";
// Actions
import { setRouterCurrentPage } from "../state/actions";
// Components
import { Flex, Box, Text, Heading, Image, Card } from "theme-ui";
import { QuestionLayout } from "../components/layouts/withQuestionLayout";

export const FavoriteDessertPage = ({ handleAnswerSelection }) => {
	// Content
	const { title, subtitle } = CONTENT.favoriteDessertPage;
	const { options } = QUIZ.favoriteDessert;

	// Handle hover effect state
	const defaultHoverState = options.reduce((acc, option, idx) => {
		return { ...acc, [idx]: false };
	}, {});

	const [hoverStates, setHoverState] = useState(defaultHoverState);

	const updateHoverState = ({ idx, val }) =>
		/**
		 * This function takes an specific card index and
		 * updates its hover state.  It uses defaultHoverState
		 * to ensure that even if mouseLeave event doesnt fire
		 * that only one is hovered at a time.
		 */
		setHoverState({
			...defaultHoverState,
			[idx]: val
		});

	return (
		<Box
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
        textAlign: "center",
        width: "100%"
			}}
		>
			<Heading as="h1" mb={1} variant="heading">
				{title}
			</Heading>
			<Text
				as="p"
				sx={{
          fontSize: [null, 2, 3],
          margin: 'auto',
					color: "grey",
          maxWidth: [null, null, "50%", "45%"],
          textAlign: 'center',
					display: ["none", "none", "block"]
				}}
			>
				{subtitle}
			</Text>
			{/* Cards */}
			<Flex
				sx={{
					height: "auto",
					width: "100%",
					maxWidth: 1000,
					mt: [5],
					mx: "auto",
					flexWrap: "wrap",
					alignItems: "space-between",
					justifyContent: "center",
					flex: 1
				}}
			>
				{options?.map((option, idx) => (
					<Card
						// Save hover state for each card
						onMouseEnter={() => updateHoverState({ idx, val: true })}
            onMouseLeave={() => updateHoverState({ idx, val: false })}
            onClick={() => handleAnswerSelection({response: option.flavours})}
						key={option.title}
						sx={{
							// height: "100%",
							minWidth: ["35%", "35%", 0, 0],
							maxWidth: [null, null, "180px", "180px", "unset"],
							flex: "1",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-center",
							justifyContent: "space-between",
							mx: [1, 2, 3],
              mb: [idx + 1 <= options.length - 2 ? 5 : 0, 5, 0, 0], // exclude last 2
              userSelect: 'none',
						}}
					>
						<Image
							sx={{
								position: "relative",
								top: [-5,-5,-5,-5,option.wide ? 0 : -5],
								left: option.wide ? "-15%" : 0,
								right: "0",
								width: "option.wide" ? "130%" : "100%",
								maxWidth: option.wide ? "130%" : "100%",
								// Image scales when parent is hovered
								transition: ".35s cubic-bezier(0.250, 0.460, 0.450, 0.940)",
                transform: hoverStates[idx] ? "scale(1.05)" : "",
                userSelect: 'none',
							}}
							src={`/quiz/desserts/${option.image}.png`}
						/>
						<Text
							sx={{
								mt: -5,
								mb: 2,
								fontSize: [3, 3, 4],
								fontWeight: "bold"
							}}
						>
							{option.title}
						</Text>
					</Card>
				))}
			</Flex>
		</Box>
	);
};
