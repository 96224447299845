// Components
import { Flex, Box, Heading, Button, Card, Text, Image, Link } from "theme-ui";
import { Icon } from "../components/atoms/images";
import { BreakPointUtility } from "../components/atoms/utils/BreakPointUtility";
import { wobble } from "../components/animations";
// Actions
import {
	setRouterCurrentPage,
	setActiveResult,
	clearQuiz
} from "../state/actions";
// Content
import { QUIZ } from "../config/";

const debugColors = false;

export const WideCard = ({ icon, accentColor, heading, content }) => (
	<Card
		variant="base"
		sx={{
			p: 0,
			display: "flex",
			width: "100%",
			borderRadius: "6px",
			background: "#fff",
			border: 0,
			boxShadow: "0px 3px 56px rgba(9, 10, 10, 0.06)",
			overflow: "hidden",
			justifyContent: "space-between",
			my: 2
		}}
	>
		<Flex
			sx={{
				flexDirection: "column",
				px: [3, 3, 4, 4, 5],
				py: [4, 4, 4, 4, 3, 4]
			}}
		>
			<Text
				sx={{
					fontWeight: "bold",
					fontSize: [2, 2, 2, 2, 2, 3],
					mb: 2,
					color: "black"
				}}
			>
				{heading}
			</Text>
			<Text sx={{ fontSize: [1, 1, 1, 1, 1, 2] }}>{content}</Text>
		</Flex>

		{icon && (
			<Flex
				sx={{
					width: "px",
					bg: "rgba(170, 111, 85, 0.20)",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Image src={icon} />
			</Flex>
		)}
	</Card>
);

export const ResultsPage = ({
	dispatch,
	activeResult,
	quizResults,
	nextPage,
	prevPage
}) => {
	// Content
	const {
		title,
		theme,
		link,
		flavour,
		image,
		content: { tastesLike, customersSay, useItIn, tagline }
	} = QUIZ.products[activeResult];
	// Debug this page with fake results
	// quizResults = [
	// 	"birthday_cake",
	// 	"roasted_hazelnut",
	// 	"peppermint_mocha",
	// 	"cacao_mocha"
	// ];

	if (quizResults.length < 1) {
		dispatch(setRouterCurrentPage({ page: "start" }));
	}

	// Handlers
	const handleBackClick = () => {
		dispatch(clearQuiz());
		dispatch(setRouterCurrentPage({ page: "start" }));
	};

	return (
		<Box
			sx={{
				display: "grid",
				width: "100%",
				height: [, , , "auto", "calc(100% - 30px)"],
				gridTemplateRows: [
					"350px 175px auto 200px",
					"500px 175px auto 250px",
					"500px 175px auto 250px",
					"500px 300px auto 140px",
					"20% 60% 10% 10%"
				],
				// Desktop: First Small Percent is mug off bound, before text, second is mug on text
				gridTemplateColumns: [
					"60% 40%",
					"60% 40%",
					"60% 40%",
					"60% 40%",
					"25% 2% 3% 40% 30%"
				]
			}}
		>
			{/* <BreakPointUtility /> */}
			<Box
				sx={{
					background: debugColors ? "lightyellow" : "white",
					width: "100%",
					gridRow: ["1/2", "1/2", "1/2", "1/2", "1/5"],
					gridColumn: ["1/2", "1/2", "1/2", "1/2", "1/2"],
					height: "100%"
				}}
			>
				{/* Half Moon Shape */}
				<Flex
					sx={{
						flexDirection: "column",
						backgroundColor: theme.backgroundColor,
						height: "100%",
						width: [, , "100%", "100%", "100%"],
						borderTopRightRadius: [0, 0, 0, 0, "300px"],
						borderBottomRightRadius: [
							"75px",
							"75px",
							"200px",
							"200px",
							"300px"
						],
						pt: [4],
						pl: [4],
						position: "relative",
						zIndex: "2",
						overflow: "hidden"
					}}
				>
					<Icon
						icon="left_arrow"
						description="Restart Quiz"
						onClick={handleBackClick}
						// mb={[2, 2, 3, 5, 4, 5]}
					/>
					{/* Text */}
					<Flex
						sx={{
							// pl: 4,
							mr: [3, 3, 4, 3, 6, 6],
							pt: [2, 2, 3, 5, 4, 5],
							pb: [2, 2, 3, 5, 5, 5],
							flexDirection: "column",
							flex: 1,
							height: "100%",
							justifyContent: "space-between",
							lineHeight: "1.4",
							maxHeight: "80vh"
						}}
					>
						<Text
							sx={{
								fontSize: [3, 3, 5, 6, 4, 6],
								fontWeight: "bold",
								color: theme.invert
									? "rgba(0, 0, 0, .20)"
									: "rgba(255, 255, 255, .20)"
							}}
						>
							{tagline}
						</Text>
						<Text
							sx={{
								fontSize: [3, 3, 5, 6, 4, 6],
								fontWeight: "bold",
								color: theme.invert
									? "rgba(0, 0, 0, .20)"
									: "rgba(255, 255, 255, .20)",
								alignSelf: "flex-end"
							}}
						>
							{tagline}
						</Text>
					</Flex>
				</Flex>
			</Box>
			{/* Info Section */}
			<Box
				sx={{
					background: debugColors ? "lightblue" : "white",
					width: "100%",
					gridRow: ["3/4", "3/4", "3/4", "3/4", "1/3"],
					gridColumn: ["1/3", "1/3", "1/3", "1/3", "2/5"],
					overflowY: [, , , , "scroll"]
				}}
			>
				<Flex
					sx={{
						flexDirection: "column",
						width: "100%",
						height: "100%",
						backgroundColor: debugColors ? "RosyBrown" : "white",
						mt: [4, 6, 4, 4, 5, 5],
						pr: [3, 3, 5, 5],
						pl: [3, 3, 5, 4]
					}}
				>
					<Flex sx={{ flexDirection: "column", mb: [3, 5, 5, 5, 3, 3] }}>
						<Text sx={{ color: "grey", mb: [0] }}>
							WE THINK YOU'LL LOVE THESE
						</Text>
						<Heading sx={{ mb: [1] }}>{title}</Heading>
						<Link
							target="_blank"
							sx={{ color: "black" }}
							href="https://prymal.com/pages/how-to-make-the-best-prymal-latte"
						>
							<Text sx={{ fontWeight: "bold", textDecoration: "underline" }}>
								Your Perfect Cup
							</Text>
						</Link>
					</Flex>
					<Flex
						sx={{
							width: "100%",
							flexDirection: "column",
							mb: [4, 4, 4, 4, 2, 5],
							pr: [0, 0, 0, 0, 5]
						}}
					>
						{/*Margin Inverse Card Padding */}
						<WideCard
							content={tastesLike}
							heading="Tastes Like:"
							icon={
								QUIZ.flavours[flavour]?.image &&
								`icons/tastes_like/${QUIZ.flavours[flavour].image}`
							}
						/>
						<WideCard content={customersSay} heading="Customers Say:" />
						<WideCard content={useItIn} heading="Use It In:" />
					</Flex>
					<Flex
						sx={{
							ml: [0, 0, 0, 0, 5],
							mt: [0, 0, 0, 0, 0, "auto"],
							mb: [5],
							justifyContent: ["center", , , , "flex-start"]
						}}
					>
						<Button
							sx={{
								width: [200, , 245],
								"::after": {
									backgroundColor: theme.backgroundColor,
									opacity: 0.4
								}
							}}
						>
							Get Flavor
						</Button>
					</Flex>
				</Flex>
			</Box>
			{/* Selector */}
			<Flex
				sx={{
					background: debugColors ? "coral" : "white",
					width: "100%",
					gridRow: ["2/3", "2/3", "2/3", "2/3", "1/4"],
					gridColumn: ["1/3", "1/3", "1/3", "1/3", "5/6"],
					flexDirection: ["row", "row", "row", "row", "column"],
					justifyContent: "space-evenly",
					py: [3, 3, 2, 5, 4],
					pl: [, , , 5, 3, 4],
					pr: [, , , 5, 4, 5],
					flexWrap: ["wrap", "nowrap"]
				}}
			>
				{quizResults.map(result => (
					<Card
						key={result}
						onClick={() => dispatch(setActiveResult({ result }))}
						onMouseOver={() => dispatch(setActiveResult({ result }))}
						sx={{
							display: "flex",
							cursor: "pointer",
							background: QUIZ.products[result].theme.backgroundColor,
							width: ["50%", "100%"],
							flexDirection: ["column", "column", "column", "column", "row"],
							alignItems: ["center", "center", "center", "center", "center"],
							justifyContent: [
								"center",
								"center",
								"center",
								"center",
								"flex-start"
							],
							border: false ? "default" : "transparent",
							boxShadow: activeResult === result
								? "18px 6px 40px 0px rgba(17,22,23,0.4)"
								: ''
						}}
						variant="short"
					>
						<Image
							sx={{
								width: ["30%", "10%", "30%", "30%", "30%"],
								position: [null, null, null, null, "relative"],
								bottom: -3
							}}
							src={`mugs/${QUIZ.products[result].image}` || ""}
						/>
						<Flex
							sx={{
								flexDirection: "column",
								justifyContent: "center",
								px: [0, 0, 0, 0, 3],
								color: "white",
								textAlign: ["center", "center", "center", "center", "left"],
								width: ["70%"]
							}}
						>
							<Text
								sx={{
									fontSize: [2, 3, 3, 3, 3],
									fontWeight: "bold",
									color: QUIZ.products[result].theme.invert ? "black" : "white"
								}}
							>
								{QUIZ.products[result].title}
							</Text>
							<Text
								sx={{
									fontSize: [1, 2, 2, 2, 2],
									display: ["none", "none", "none", "inline"],
									color: QUIZ.products[result].theme.invert ? "black" : "white"
								}}
							>
								Cinnamon rolls & brown sugar crumble
							</Text>
						</Flex>
					</Card>
				))}
			</Flex>
			{/* Tea Cup */}
			<Flex
				sx={{
					// background: "pink",
					gridRow: ["1/2", "1/2", "1/2", "1/2", "1/5"],
					gridColumn: ["1/3", "1/3", "1/3", "1/3", "1/4"],
					width: "100%",
					justifyContent: ["center", , , , "flex-end"],
					alignItems: ["center"],
					pl: [2]
				}}
			>
				<Image
					src={`mugs/${image}` || ""}
					sx={{
						// width: ["55%", "55%", "35%", "55%", "70%", "55%"],
						// height: '100%'
						mt: ["10%", "10%", "0"],
						height: ["80%", , "90%", "90%", "auto"],
						width: [, , "auto", "auto", "100%"],
						zIndex: 3
					}}
				/>
			</Flex>
			{/* Bottom Bar */}
			<Box
				sx={{
					gridRow: ["4/5", "4/5", "4/5", "4/5", "4/5"],
					gridColumn: ["1/3", "1/3", "1/3", "1/3", "1/6"],
					backgroundColor: "primaryLight",
					zIndex: "1"
				}}
			>
				<Box sx={{ width: "100%", borderTop: "2px solid black" }} />
				<Box sx={{ width: "100%", mt: 1, borderTop: "2px solid black" }} />
				<Flex
					sx={{
						flexDirection: ["column", "column", "column", "column", "row"],
						height: "90%",
						px: 4,
						py: [4, 4, 4, 4, 0],
						alignItems: "center",
						justifyContent: "space-evenly"
					}}
				>
					{/* <Text sx={{ mb: "auto", mt: "auto" }}>
						Psst, want to get 15% off your next order? Fill out our survey!
					</Text>
					<Button variant="outline">Fill out Survey</Button> */}
				</Flex>
			</Box>
		</Box>
	);
};
