import {
	SET_CURRENT_PAGE,
	SET_ACTIVE_RESULT,
	ADD_QUIZ_RESPONSE,
	UNDO_QUIZ_RESPONSE,
	CALCULATE_RESULTS,
	CLEAR_QUIZ,
} from "./constants";
import { QUIZ } from "../config";
import { forEach } from "lodash";

/**
 * Handles routing.
 * Pass page string that matches ROUTES
 */
export const setRouterCurrentPage = ({ page }) => {
	return {
		type: SET_CURRENT_PAGE,
		payload: page
	};
};

/**
 * Set active an result string for result page
 */
export const setActiveResult = ({ result }) => {
	return {
		type: SET_ACTIVE_RESULT,
		payload: result
	};
};

export const calculateResults = ({ responses }) => {
	const { products } = QUIZ;
	let acc = [];
	let result = null;

	responses.forEach(response => {
		forEach(products, product => {
			if (product.flavour === response && !acc.includes(product.id)) {
				acc = [...acc, product.id];
			}
		});
		
	});
		const short = acc.slice(0, 3); // Trim to  max length of 3
		const filled = [...short, 'variety_pack']
		result = filled
	return {
		type: CALCULATE_RESULTS,
		payload: result
	};
};

/**
 * Add an array of flavour strings that were selected in the quiz question.
 */
export const addQuizResponse = ({ response }) => {
	return {
		type: ADD_QUIZ_RESPONSE,
		payload: response
	};
};

/**
 * Undo a quiz response. Takes no parameters.
 * Simply removes two items from end of response array.
 */
export const undoQuizReponse = () => {
	return {
		type: UNDO_QUIZ_RESPONSE
	};
};

export const clearQuiz = () => {
	return {
		type: CLEAR_QUIZ
	}
}