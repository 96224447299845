import * as React from "react";
import {
	SliderItem,
	GetHandleProps,
	GetTrackProps
} from "react-compound-slider";
import {Icon} from '../images/Icon'
import {Box} from 'theme-ui'

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
// interface IHandleProps {
//   domain: ReadonlyArray<number>;
//   handle: SliderItem;
//   getHandleProps: GetHandleProps;
// }

export const Handle = ({
	domain: [min, max],
	handle: { id, value, percent },
  getHandleProps,
  isSliderActive,
}) => (
	<Icon
		role="slider"
		aria-valuemin={min}
		aria-valuemax={max}
		aria-valuenow={value}
		sx={{
			left: `${percent}%`,
			position: "absolute",
			marginLeft: "-37px",
			marginTop: "-13px",
      zIndex: 2,
      transition: isSliderActive ? '' : '.3s ease',

			cursor: "pointer",
			// borderRadius: "50%",
			// boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
			// backgroundColor: "#34568f"
    }}
    minWidth={60}
    // width={60}
    height={50}
    icon='mug'
    // <Icon />
		{...getHandleProps(id)}
	/>
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
// interface ITrackProps {
//   source: SliderItem;
//   target: SliderItem;
//   getTrackProps: GetTrackProps;
// }

export const Track = ({ source, target, getTrackProps }) => {
	return (
		<div
			style={{
				position: "absolute",
				height: 14,
        zIndex: 1,
        // Add background to enable progress
				// background: `linear-gradient(217deg, #ffe2c1 0%, #be8b55 52.71%, #5a3005 100%)`,
				borderRadius: 7,
				cursor: "pointer",
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`
      }}
			{...getTrackProps()}
		/>
	);
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export const Tick = ({ tick, count, format = d => d }) => {
	return (
		<div>
			<div
				style={{
					position: "absolute",
					marginTop: 14,
					width: 1,
					height: 5,
					backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`
				}}
			/>
			<Box
				style={{
					position: "absolute",
					marginTop: 22,
          fontSize: [12, 14, 16],
          fontWeight: 'bold',
					textAlign: "center",
					marginLeft: `${-(100 / count) / 2}%`,
					width: `${100 / count}%`,
          left: `${tick.percent}%`,
				}}
			>
				{format(tick.value)}
			</Box>
		</div>
	);
};
