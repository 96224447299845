// Config
import { CONTENT } from "../../config";
// Components
import { Box, Flex, Text, Image, Link } from "theme-ui";
import { SocialIcon } from "react-social-icons";

export const PromoBar = ({ content, ...rest }) => {
	return (
		<Flex
			className="promobar"
			justifyContent="center"
			alignItems="center"
			p={[2, 2, 3]}
			fontSize={1}
			sx={{ height: "fit-content", textAlign: "center", fontWeight: "bold" }}
			{...rest}
		>
			<Text fontWeight="heading">{content || ""}</Text>
		</Flex>
	);
};

export const NavBar = ({ socialLinks, ...rest }) => {
	return (
		<Flex
			className="navbar"
			// justifyContent="center"
			alignItems="center"
			pl={15}
			sx={{ height: 30, minHeight: 30, alignItems: "center" }}
			{...rest}
		>
			<Flex className="social-icons" alignItems="center" width={5 / 10}>
				{socialLinks.map((link, idx) => (
					<SocialIcon
						key={idx}
						url={link}
						style={{
							height: link[0] !== "m" ? 30 : 35,
							width: link[0] !== "m" ? 30 : 35
						}}
						bgColor="transparent"
						fgColor="black"
					/>
				))}
			</Flex>
			<Link
				href="https://prymal.com"
				sx={{
					width: "50px",
					position: "absolute",
					left: "50%",
					transform: "translateX(-50%)",
					lineHeight: 0,
				}}
			>
				<Image sx={{ width: "100%" }} src="/logo.png" />
			</Link>
		</Flex>
	);
};

export const CoreLayout = ({ children }) => {
	return (
		<Flex
			sx={{ height: ["auto", "100%"], width: "100%", flexDirection: "column" }}
			className="core-layout"
		>
			{/* {PromoBar && (
        <PromoBar
          minHeight={"30px"}
          backgroundColor={"#EAE1D1"}
          content={CONTENT.promoBar}
        />
      )} */}
			<NavBar
				minHeight={"40px"}
				backgroundColor={"#E6E6E6"}
				socialLinks={CONTENT.socialLinks}
			/>
			{children}
		</Flex>
	);
};
