// Actions
import {
	setRouterCurrentPage,
	calculateResults,
	addQuizResponse,
	undoQuizReponse
} from "../../state/actions";
// Components
import { Flex, Box, Text, Heading, Image, Card } from "theme-ui";
import { Tick } from "../atoms/misc";
import { Icon } from "../atoms/images";
import { wobble } from "../animations";

export const withQuestionLayout = ({
	component: WrappedComponent,
	dispatch,
	nextPage,
	prevPage,
	questionTracker,
	quizResponses
}) => {
	const handleAnswerSelection = ({ response }) => {
		// TODO: Add answer selection in state
		// TODO: Record answer selection in CRM.
		if (response) {
			dispatch(addQuizResponse({ response: response }));
		}
		dispatch(setRouterCurrentPage({ page: nextPage }));
	};

	const completeQuiz = () => {
		// Set state for answer selection
		dispatch(calculateResults({ responses: quizResponses }));
		// dispatch(setRouterCurrentPage({ page: nextPage }));
	};

	const handleBackClick = () => {
		dispatch(undoQuizReponse());
		dispatch(setRouterCurrentPage({ page: prevPage }));
	};

	return (
		<Flex sx={{ height: ["auto", "auto", "100%"], flexDirection: "column" }}>
			<Box
				sx={{
					height: ["unset", "unset", "unset", "unset", "100%"],
					mx: [3, 4, 4],
					mt: 4,
					px: [3, 3, 4],
					pt: [3, 3, 3, 4],
					pb: [3, 3, 3, 4],
					mb: 0,
					height: "100%",
					flexDirection: "column",
					// backgroundImage: "radial-gradient(#efe4d5 0%, #f8f5f5 100%)",
					backgroundImage: "linear-gradient(#EDEDED 0%, #F8F5F5 100%)",
					borderRadius: "rounded"
				}}
			>
				<Icon
					icon="left_arrow"
					description="Back"
					onClick={handleBackClick}
					mb={[4, 4, 3, 0, 0, 5]}
					sx={{
						":hover": {
							animation: `${wobble} .4s ease 1`,
						}
						// animationName: wobble.toString(),
						// animationTimingFunction: "linear",
						// animationDuration: 500,
						// animationIterationCount: "infinite"
					}}
				/>
				{/* Question Page is Injected Here */}
				<WrappedComponent
					completeQuiz={completeQuiz}
					handleAnswerSelection={handleAnswerSelection}
				/>
				{/* I'm Mister Meeseeks look at me! */}
			</Box>
				<Box sx={{marginTop: 'auto'}}>
				<Box sx={{width: "100%", borderTop: '2px solid black', marginBottom: 2}} />
				<Box sx={{width: "100%", borderTop: '2px solid black'}} />
				</Box>
			<Flex sx={{ flexGrow: "1", width: "100%", background: "white" }}>
				<Flex
					sx={{
						width: "100%",
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "75px",
						my: [0, 0, 0],
						mb: [4, 3, 0]
					}}
				>
					{/* Map over element a certain number of times */}
					{Array(questionTracker.numberOfQuestions)
						.fill("")
						.map((val, idx) => (
							<Tick
								key={`tick-${idx}`}
								wasOn={idx + 1 < questionTracker.questionCount}
								isOn={idx + 1 === questionTracker.questionCount}
							/>
						))}
				</Flex>
			</Flex>
		</Flex>
	);
};
