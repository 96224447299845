// Icons
import {
	Flex,
	Box,
	Heading,
	Button,
	Card,
	Text,
	Image,
	AspectImage
} from "theme-ui";
import { Icon } from "../components/atoms/images";
// import {BreakPointUtility} from '../components/atoms/utils/BreakPointUtility'
// Actions
import { setRouterCurrentPage } from "../state/actions";
// Content
import { QUIZ } from "../config/";

const debugColors = false;

export const LeftThird = ({ handleBackClick, tagline }) => (
	<Flex
		sx={{
			width: ["100%", "100%", "100%", "100%", "35%"],
			height: "100%",
			backgroundColor: debugColors ? "red" : "white"
		}}
	>
		{/* Overflow Hidden Container for cut off effect on bottom */}
		<Flex
			sx={{
				height: "100%",
				width: "100%",
				overflow: "hidden"
			}}
		>
			{/* Half Moon Shape % */}
			<Flex
				sx={{
					height: "110%",
					width: ["65%", "65%", "65%", "60%", "100%", "100%"],
					flexDirection: "column",
					backgroundColor: "#7BAEC3",
					zIndex: 2,
					// Shape
					mr: "15%",
					borderTopRightRadius: ["0px", "0px", "0px", "0px", "300px", "400px"],
					borderBottomRightRadius: [
						"400px",
						"400px",
						"400px",
						"300px",
						"300px",
						"400px"
					],
					// Positioning Children
					pt: [4, 4, 5, 5, 5, 5],
					pl: [3, 3, 5, 5, 5, 5],
					lineHeight: ["1.5", "1.5", "1.5", "1.5", "1.4", "1.5"],
					overflow: ["hidden", "hidden", "visible"],
					position: ["static", "static", "relative"]
				}}
			>
				{/* Back */}
				<Icon
					icon="left_arrow"
					description="Back"
					onClick={handleBackClick}
					mb={[2, 2, 3, 5, 5]}
				/>
				{/* Text */}
				<Flex sx={{ pl: 4, mr: [3, 3, 4, 3, 5, 6] }}>
					<Text
						sx={{
							fontSize: [3, 3, 5, 6, 4, 6],
							fontWeight: "bold",
							// lineHeight: "1.7",
							color: "rgba(255, 255, 255, .29)"
						}}
					>
						{tagline}
					</Text>
				</Flex>
				{/* Teacup - Counteract mr of half moon with inverse mr */}
				<Flex
					sx={{
						display: ["block", "block", "flex"],
						mr: ["0%", "0%", "-45%", "-65%", "-5%", "-15%"],
						mt: ["0%", "0%", "-35%", "-35%", "0%", "-10%"],
						justifyContent: "center",
						position: ["absolute", "absolute", "absolute"],
						top: ["30%", "initial", "40%", "80%", "50%"],
						// left: 6,
						right: [null, null, "auto", 0],
						transform: "translateY(-50%)",
						// left: ['40%']
						ml: ["25%", "25%", 0]
					}}
				>
					<Image
						src="/mugs/original.png"
						sx={{
              width: ["55%", "55%", "35%", "55%", "70%", "55%"],
              mr: [0,0,0,0,"-10%",0],
							transform: [
								"scale(1.55)",
								"scale(1.55)",
								"scale(1.55)",
								"scale(1.55)",
								"scale(1.55)",
								"scale(1.55)"
							]
						}}
					/>
				</Flex>
				{/* Text */}
				<Flex
					sx={{
						pl: 4,
						mr: [3, 3, 4, 3, 5, 5],
						mt: [-6, -6, -6, "50%", "50%"],
						alignSelf: "flex-end"
					}}
				>
					<Text
						sx={{
							fontSize: [3, 3, 5, 6, 4, 6],
							fontWeight: "bold",
							display: ["none", "none", "inline", "inline", "inline", "inline"],
							color: "rgba(255, 255, 255, .29)"
						}}
					>
						{tagline}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	</Flex>
);

export const BreakPointUtility = () => (
	<Flex
		sx={{
			position: "absolute",
			top: "0",
			right: 0,
			width: "200px",
			height: "100px",
			bg: "purple",
			color: "white",
			justifyContent: "center",
			alignItems: "center",
			fontSize: 8
		}}
	>
		<Text sx={{ display: ["block", "none", "none", "none", "none"] }}>1</Text>
		<Text sx={{ display: ["none", "block", "none", "none", "none"] }}>2</Text>
		<Text sx={{ display: ["none", "none", "block", "none", "none"] }}>3</Text>
		<Text sx={{ display: ["none", "none", "none", "block", "none"] }}>4</Text>
		<Text sx={{ display: ["none", "none", "none", "none", "block", "none"] }}>
			5
		</Text>
		<Text sx={{ display: ["none", "none", "none", "none", "none", "block"] }}>
			6
		</Text>
	</Flex>
);

export const WideCard = ({ icon, accentColor, heading, content }) => (
	<Card
		sx={{
			p: 0,
			display: "flex",
			width: "100%",
			borderRadius: "6px",
			background: "#fff",
			// border: 0,
			boxShadow: "0px 3px 56px rgba(9, 10, 10, 0.06)",
			overflow: "hidden",
			justifyContent: "space-between",
			my: 2
		}}
	>
		<Flex
			sx={{ flexDirection: "column", pl: 5, pr: 5, py: [4, 4, 4, 4, 3, 4] }}
		>
			<Text sx={{ fontWeight: "bold", fontSize: [2,2,2,2,2,3], mb: 2, color: "black" }}>
				{heading}
			</Text>
			<Text sx={{fontSize: [1,1,1,1,1,2]}}>{content}</Text>
		</Flex>

		{icon && (
			<Flex
				sx={{
					width: "30%",
					bg: "rgba(170, 111, 85, 0.20)",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Image src={`/icons/${icon}.png`} />
			</Flex>
		)}
	</Card>
);

export const ResultsPage = ({ dispatch, nextPage, prevPage }) => {
	// Content
	const {
		title,
		tastesLike,
		customersSay,
		useItIn,
		tagline
	} = QUIZ.products.cinnamon_dolce;
	// Handlers
	const handleBackClick = () => {
		dispatch(setRouterCurrentPage({ page: prevPage }));
	};

	return (
		<Flex
			sx={{
				flexDirection: ["column", "column", "column", "column", "row"],
				height: "100%"
			}}
		>
			<BreakPointUtility />
			{/* Left Third, with coffee cup overlay */}
			<LeftThird handleBackClick={handleBackClick} tagline={tagline} />
			<Flex
				sx={{
					width: ["100%", "100%", "100%", "100%", "65%"],
					height: "100%",
					backgroundColor: debugColors ? "blue" : "white"
				}}
			>
				{/* Text */}
				<Flex
					sx={{
						flexDirection: "column",
						width: "50%",
						backgroundColor: debugColors ? "RosyBrown" : "white",
            mt: [6, 6, 6, 6, 5, 6],
					}}
				>
					<Flex sx={{ flexDirection: "column", mb: [5, 5, 5, 5, 3, 5] }}>
						<Text sx={{ color: "grey", mb: [0] }}>
							WE THINK YOU'LL LOVE THESE
						</Text>
						<Heading sx={{ mb: [1] }}>Vanilla Bean</Heading>
						<Text sx={{ fontWeight: "bold", textDecoration: "underline" }}>
							Your Perfect Cup
						</Text>
					</Flex>
					<Flex sx={{ width: "100%", flexDirection: "column", ml: -5, mb: [4,4,4,4,4,5] }}>
						{/*Margin Inverse Card Padding */}
						<WideCard
							content={tastesLike}
							heading="Tastes Like:"
							icon="tastes_like/cinnamon"
						/>
						<WideCard content={customersSay} heading="Customers Say:" />
						<WideCard content={useItIn} heading="Use It In:" />
					</Flex>
					<Flex sx={{}}>
						<Button>Get Flavour</Button>
					</Flex>
				</Flex>
				{/* Recomendation */}
				<Flex
					sx={{
						width: "50%",
						backgroundColor: debugColors ? "MediumOrchid" : "white"
					}}
				></Flex>
			</Flex>
			{/* Bottom Bar */}
			<Flex
				sx={{
					background: "green",
					width: "100%",
					height: "80px",
					bottom: 0,
					zIndex: 1,
					position: "absolute"
				}}
			>
				{/* Liner */}
				<Box
					sx={{
						width: "100%",
						height: 15,
						backgroundColor: "white",
						borderTop: "2px solid black",
						borderBottom: "2px solid black"
					}}
				/>
			</Flex>
		</Flex>
	);
};
