import { Box } from "rebass";

export const Tick = React.memo(({ isOn, wasOn, children }) => (
	<Box
		sx={{
			height: 15,
			width: 35,
			mx: 1,
			backgroundColor: isOn ? "black" : wasOn ? "primary" : "muted",
			borderWidth: "2px",
			borderStyle: "solid",
			borderColor: isOn ? "black" : wasOn ? "black" : "lightgrey",
			borderRadius: 4
		}}
	>
		{children}
	</Box>
));
