import {
	SET_CURRENT_PAGE,
	SET_ACTIVE_RESULT,
	ADD_QUIZ_RESPONSE,
	UNDO_QUIZ_RESPONSE,
	CALCULATE_RESULTS,
	CLEAR_QUIZ,
} from "./constants";

export const initialState = {
	routerCurrentPage: "start",
	activeResult: "original",
	quizResponses: [],
	quizResults: [],
};

export const reducer = (state, { type, payload }) => {
	switch (type) {
		case SET_CURRENT_PAGE:
			return {
				...state,
				routerCurrentPage: payload
			};
		case SET_ACTIVE_RESULT:
			return {
				...state,
				activeResult: payload
			};
		case ADD_QUIZ_RESPONSE:
			return {
				...state,
				quizResponses: [...state.quizResponses, ...payload]
			};
		case UNDO_QUIZ_RESPONSE:
			/**
			 * Very basic undo function. Trims 2 items off the end of the array.
			 * Only works because each response contains two array items.
			 * If this changes, quiz response will need to be more complex.
			 */
			return {
				...state,
				quizResponses: state.quizResponses.slice(0, state.quizResponses.length - 2)
			};
		case CALCULATE_RESULTS: 
			return {
				...state,
				quizResults: payload
			}
		case CLEAR_QUIZ: 
			return {
				...state,
				quizResults: initialState.quizResults,
				quizResponses: initialState.quizResponses
			}
		default:
			throw new Error("Reducer type error! Check dispatch type, or reducer.");
	}
};
